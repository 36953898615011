import styled from "styled-components";

export const Mobile = styled.div`
  box-shadow: 0 0 0 3px #fff, 0 0 0 10px #eaeaea, 0 0 0 15px #fff;
  border-radius: 12px;
  background: ${(props) => props.theme.colors.white};
  max-height: 100%;
  height: 600px;
  max-width: 320px;
  overflow-y: auto;
  margin-top: 15px;
  color: black;

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 7px;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;

export const SwiperWrapper = styled.div`
  // border-radius: 12px 12px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Body = styled.div`
  margin-top: 20px;
  padding: 0 ${(props) => props.theme.space[3]}px;
  margin-bottom: 20px;
  // border-radius: 0 0 12px 12px;
`;

export const MerchantName = styled.h3`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: ${(props) => props.theme.space[2]}px;
`;

export const MerchantAddress = styled.span`
  font-size: 14px;
  color: #8e8e8e;
  margin-bottom: 20px;
  display: block;
  lineheight: 20px;
`;

export const Category = styled.div`
  border: 1px dashed #acacac;
  border-radius: 7px;
  padding: 8px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CategoryImg = styled.img`
  height: 25px;
`;

export const CategoryText = styled.span`
  font-weight: 600;
  font-size: 16px;
`;

export const VoucherDetails = styled.div`
  margin-top: 30px;
`;

export const Heading = styled.h3`
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Akkurat-Mono", sans-serif;
  letter-spacing: 2px;
`;

export const Wrapper = styled.div`
  padding: ${(props) => props.theme.space[3]}px 0;
  border-bottom: 1px solid #eaeaea;
`;

export const Title = styled.h3`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: ${(props) => props.theme.space[1]}px;
`;

export const Text = styled.span`
  font-size: 14px;
`;

export const Features = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`;

export const List = styled.ul`
  display: flex;
  margin-top: ${(props) => props.theme.space[2]}px;
`;

export const Item = styled.li``;
