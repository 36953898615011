import styled from "styled-components";

export const DashboardContent = styled.div`
  padding: 0 ${(props) => props.theme.space[4]}px;
  background-color: ${(props) => props.theme.colors.lightGray};
  // flex: 5;
  width: 80%;
`;

export const Title = styled.h3`
  font-size: 50px;
  color: ${(props) => props.theme.colors.white};
  padding-bottom: 40px;
  font-weight: 500;
`;

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: ${(props) => props.theme.space[5]}px;
  border: 1px solid ${(props) => props.theme.colors.gray};
  border-radius: 12px;
`;

export const ChartWrapper = styled.div`
  padding: 24px 0;
  padding-right: ${(props) => props.theme.space[4]}px;
`;
