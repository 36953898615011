import styled from "styled-components";

export const LoginPage = styled.div`
  background-color: ${(props) => props.theme.colors.bg};
  height: 100vh;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
export const LoginWrapper = styled.div`
  display: flex;
  gap: 20px;
  /* position: absolute; */
  left: 0;
  right: 0;
  min-height: calc(100vh - 87px);
  /* top: 50%; */
  /* transform: translateY(-50%); */
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  width: 640px;
  max-width: 100%;
  margin: 0 auto;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    max-width: 100%;
    padding: 0 40px;
  }
`;
export const Title = styled.h3`
  font-size: 40px;
  font-weight: normal;
  text-align: left;
  margin-bottom: ${(props) => props.theme.space[3]}px;
`;
export const Subtitle = styled.span`
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  display: block;
  margin-bottom: 40px;
  text-align: left;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 20px;
  width: 50%;
  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: 500px;
    height: 500px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 500px;
    height: 300px;
    margin-top: 50px;
  }

  @media screen and (max-width: 320px) {
    width: 100%;
    height: 280px;
    max-width: 280px;
  }
`;

export const Link = styled.span`
  display: block;
  text-align: right;
  margin: ${(props) => props.theme.space[3]}px 0;
  margin-bottom: ${(props) => props.theme.space[4]}px;
  a {
    text-decoration: none;
    font-size: 14px;
    color: #8a8a8f;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const Form = styled.form`
  max-width: 100%;
`;
