import normalize from "./normalize";
import { createGlobalStyle } from "styled-components";
import pagination from "./pagination";
import Map from "./map";
import datepicker from "./datepicker";

const GlobalStyle = createGlobalStyle`
${normalize}
${pagination}
${Map}
${datepicker}

  html, body {
    font-family: "TT Hoves", sans-serif;
    background-color: ${(props) => props.theme.colors.bg};

  }
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  input {
    outline: 0;
  }
  ul {
    margin: 0
  }
  li {
    list-style: none;
  }
  p {
    margin: 0
  }
  a {
    transition: 0.3s;
  }
  .react-datepicker-wrapper .react-datepicker__input-container input {
    border: 1px solid #ebebeb;
  }


  .input-range__slider {
    background: #fff;
    border: 1px solid #fff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    width: 20px;
    height: 20px;
    transform: translateY(-60%);
    margin-top: 0;
  }
  .input-range__slider:active {
    transform: translateY(-60%) scale(1.2)
  }
  .input-range__track {
    background-color: #FAFAFA;
    height: 5px;
  }
  .input-range__track--active {
    background: #3CD17F;
  }
  .input-range__label--min,
  .input-range__label--max {
    display: none
  }
  .input-range__label-container {
    color: #333;
    font-weight: 500;
    font-size: 14px;
    margin-top: 8px;
    font-family: "TT Hoves", sans-serif;
  }

  .kmmrce-select-container .kmmrce-select__multi-value {
    background-color: #EBEBEB;
    color: #8E8E8E;
    border-radius: 7px;
    padding: 6px 0;
    padding-left: 6px;
    padding-right: 8px;
  }
  .kmmrce-select-container .kmmrce-select__multi-value__label {
    color: #8E8E8E;
  }
  .kmmrce-select-container .kmmrce-select__multi-value__remove {
    cursor: pointer;
    transition: 0.3s;
  }

  .kmmrce-select-container .kmmrce-select__multi-value__remove:hover {
    background-color: transparent;
    color: #fff;
    opacity: 0.5;
  }
  
  .react-tabs__tab-list {
    background: black;
    border: 1px solid #29292c !important;
    border-bottom: none;
    margin: 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .react-tabs .cards_wrapper .card:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .react-tabs__tab {
    font-weight: 500;
    /* color: #26262f; */
    padding: 25px;
    position: relative;
    &:after {
      content: "";
        width: 100%;
        height: 2px;
        background: #3cd17f;
        position: absolute;
        left: 0;
        bottom: -1px;
        opacity: 0;
        z-index: 999;
    }
  }
  .react-tabs__tab--selected {
    border: none;
    background-color: black;
    color: white;
    &:after {
      opacity: 1
    }
  }
  .react-tabs__tab--disabled {
    opacity: 0.5;
  }

  .inner-menu-item a:hover {
    color: ${(props) => props.theme.colors.primary}
  }

  .item-status {
    position: relative;
    display: flex;
    align-items:center;
.bullet {
  width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 8px;
}
    
      &.active .bullet {
        background: #3CD17F;

      }
      &.active .bullet {
        background-color: #3CD17F;

      }&.in-active .bullet {
        background-color: #fbc531;
      }
  }

  .sidebar::-webkit-scrollbar, .sidebar .inner-menu::-webkit-scrollbar {
    width: 7px;
  }
  .sidebar::-webkit-scrollbar-track,
  .table-content::-webkit-scrollbar-track,
  .sidebar .inner-menu::-webkit-scrollbar-track {
    background-color: #efefef;
    border-radius: 7px;
  }
  .sidebar::-webkit-scrollbar-thumb, 
  .table-content::-webkit-scrollbar-thumb ,
  .sidebar .inner-menu::-webkit-scrollbar-thumb{
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .table-content::-webkit-scrollbar {
      height: 7px;
  }

  .label {
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 5px;
    color: #fff;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
    &.label-delete {
      background-color: #ff6262;
    }
    &.label-update {
      background-color: #fbc531;
    }
    &.label-create {
      background-color: #3CD17F;
    }
    &.label-info {
      background-color:  rgb(198, 255, 0);
    }
  }
  //override
  .card .table th:first-letter, .card .table td:first-letter {
    text-transform: initial
  }

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 45px 0;

  }

  .page-header h1 {
    font-weight: 500;
    margin-bottom: 0;
  }

  @media screen and (min-width: 992px) {
    .page-header h1 {
      font-size: 35px;
    }
  }
  .btn.btn-purple {
    border: 1px solid transparent;
    background-color: rgba(75, 66, 174, 0.1);
    color:  rgb(198, 255, 0);
    font-weight: 500;
  }

  .btn.btn-danger {
    background-color: transparent;
    color: #FF6972;
    border-color: #ebebeb;
    height: 34px;
    line-height: 34px;
    font-size: 14px;
    padding: 0 10px;
    border: none;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    &:hover {
      background-color: rgba(255, 105, 114, 0.1);
    }
  }
  .list {
    align-items: center;
    .list-img {
      margin-right: 16px;
      &:last-child: {
        margin-right: 0
      }
    }
  }
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  .card {
    width: 100%;
  }
}

.swiper-pagination-bullet {
  background-color: #333;
}

.swiper-pagination-bullet-active {
  background-color: #3CD17F;
}

.sidebar .sidebar-sticky {
  height: auto;
}

.react-tabs__tab:focus {
  border-color: transparent;
  box-shadow: none;
  &:focus&:after {
    background: #3cd17f;
    bottom: -1px;
    left: 0;
    height: 2px;
  }
}

.cards_wrapper {
  margin-bottom: 20px;
  .card {
    border-radius: 0;
    &:first-child {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    &:last-child {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
    &:not(:last-child) {
      border-bottom: none;
    }
  }
}

.btn-toolbar ul {
  display: flex;
  z-index: 9999;
  position: absolute;
  pointer-events: initial;
  right: 50%;
}
.btn-toolbar-trigger:after {
  transform: rotate(90deg)
}

.btn.btn-link {
  background: transparent;
  /* color: #333; */
  border: none;
  padding: 0.5rem 1rem;
  padding-right: 50px;
  height: auto;
  line-height: initial;
  cursor: pointer;
}

.card .table.table-bordered {
  border-radius: 12px;
}
.card.no-overflow .card-body {
  overflow-x: initial;
}

// .card .table.table-bordered tbody tr td {
//   max-width: 250px;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   overflow-y: hidden;
// }

.input_holder {
  .input__characters-count {
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: #6c757d;
    font-size: 14px;
  }
}

.input_holder.error {
  .input__characters-count {
    bottom: 40px;
  }
}

.rokit {
  .green_text {
    color: #000
  }

  .btn-primary {
    background-color: #000;
    border-color: #000;
  }
}

.felix {
  background-color: #14181A !important;
  
  .form_action h5 a{
    color: #c6ff00 !important;
  }
  
  .btn-primary{
    background: #c6ff00 !important;
    color: black !important;
    border-color: #c6ff00 !important;
  }

  .sidebar li.active svg path {
    fill : #c6ff00 !important;
  }
  
  .inner-menu .inner-menu-item{
    color: white !important;
  }
  .inner-menu .inner-menu-item:hover {
    color: #c6ff00 !important;
  }

  .btn-toolbar ul li a:hover {
    color: #c6ff00 !important;
  }
}

.in-control {
  .form_action h5 a {
    color: #19E8E0;
  }

  .green_text {
    color: #19E8E0;
  }
  
  .inner-menu .inner-menu-item:hover {
    color:#19E8E0;
  }

  .sidebar li.active svg path {
    fill :#19E8E0;
  }


  .btn-primary {
    background: rgb(2,127,255);
    background: linear-gradient(90deg, rgba(2,127,255,1) 0%, rgba(14,183,238,1) 41%, rgba(25,232,224,1) 100%);
    border-color: linear-gradient(90deg, rgba(2,127,255,1) 0%, rgba(14,183,238,1) 41%, rgba(25,232,224,1) 100%);
  }
}

@media screen and (max-width: 1024px) {
  .dashboard_container {
    padding-top: 0;
  }
}

.password-field {
  position: relative;
 .toggle-password {
   svg {
    position: absolute;
    right: 15px;
    cursor: pointer;
    margin-bottom: 5.5px;
   }
   svg.state1 {
     bottom: 4px;
   }
   svg.state2 {
    bottom: 10px;
  }
 } 
}

.text-ellipsis {
  width: 200px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.card .table-content {
  overflow-x: auto
}

.kmmrce-radio .radio_label:before, .kmmrce-radio label:before {
  top: 50%;
  transform: translateY(-50%);
}

.kmmrce-radio  {
  display: flex;
  align-items: center;
}


.kmmrce-radio .radio_input:checked + .radio_label:before, 
.form-check .radio_input:checked + .radio_label:before, 
.form-check input[type="radio"]:checked + label:before, 
  background: #3CD17F;
  border: 1px solid #3CD17F;
}


.kmmrce-radio input[type="checkbox"]:checked + label:before, 
.form-check .radio_input:checked + .radio_label:before, 
.form-check input[type="checkbox"]:checked + label:before {
  background:  rgb(198, 255, 0);
  border: 1px solid  rgb(198, 255, 0);
}

.kmmrce-radio .radio_input + .radio_label:before,
.kmmrce-radio input[type="checkbox"] + .radio_label:before {
  border: 1px solid #EBEBEB;
}

.kmmrce-select-container .kmmrce-select__control {
  height: auto;
}
.kmmrce-select-container .kmmrce-select__value-container--is-multi.kmmrce-select__value-container--has-value {
  padding-top: 6px;

}

.kmmrce-radio input[type="checkbox"]:checked + label:after {
  top: 10px;
}


// TODO Theme

.kmmrce-switch__input + .kmmrce-switch__handler-wrapper {
background-color: #C9C9C9;
}

.kmmrce-switch__input + .kmmrce-switch__handler-wrapper .kmmrce-switch__handler  {
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  }

.kmmrce-switch__input:checked + .kmmrce-switch__handler-wrapper {
  background-color: rgba(60, 209, 127, 0.1)
  }

  .kmmrce-switch__input:checked + .kmmrce-switch__handler-wrapper .kmmrce-switch__handler  {
    background-color: #3cd17f
    }
    .kmmrce-switch__label {
      display: flex;
      align-items: center;
    }

  .react-datepicker__input-container {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      background-image: url(data:image/svg;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNy4zOTYiIGhlaWdodD0iMTUuNjc5IiB2aWV3Qm94PSIwIDAgMTcuMzk2IDE1LjY3OSI+DQogIDxnIGlkPSJDQUxFTkRBUiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExIC0xNy40KSI+DQogICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS4yIDE3LjYpIj4NCiAgICAgIDxwYXRoIGlkPSJDb21wb3VuZF9QYXRoIiBkYXRhLW5hbWU9IkNvbXBvdW5kIFBhdGgiIGQ9Ik0yOC4yLDE5LjEzM0gyNC4yNzZWMTcuNkgyMy40djEuNTMzSDE2VjE3LjZoLS44NzZ2MS41MzNIMTEuMlYzMi44NzloMTdaTTI3LjMyLDMySDEyLjA3NlYyMC4wMDloMy4wNDR2Mi40MjNIMTZWMjAuMDA5aDcuNHYyLjQyM2guODc2VjIwLjAwOUgyNy4zMloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMS4yIC0xNy42KSIgZmlsbD0iIzhlOGU4ZSIgc3Ryb2tlPSIjOGU4ZThlIiBzdHJva2Utd2lkdGg9IjAuNCIvPg0KICAgIDwvZz4NCiAgPC9nPg0KPC9zdmc+DQo=);
      width: 30px;
      height: 30px; 
      right: 0;
      top: 4px;
    }
  }

  .input_holder label span {
    position: absolute;
    right: 16px;
    top: 50%;
    height: 34px;
    line-height: 32px;
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%)
    }
    to {
      transform: translateY(0)
    }
  }

  .card .card-header {
    min-height: 68px;
  }

  .sticky-action-bar {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9999;
    background: #fff;
    width: 1300px;
    max-width: 100%;
    border: 1px solid #eaeaea;
    border-radius: 12px 12px 0 0;
    padding: 16px 20px;
    margin: auto;
    animation: slideUp 0.3s forwards;
  }

  svg +iframe {
    display: none;
  }
  .sidebar .inner-menu {
    max-height: 200px;
    max-width: 235px;
    overflow-y: auto
  }
.table.table-bordered {
  // table-layout: fixed;
  // td {
  //   min-width: 100px;
  // }
}
.flex.justify-center {
  justify-content: center
}

.kmmrce-select-container.error  .kmmrce-select__control{
  border-color:  #ffb2b2;
  margin-bottom: 10px
}
.grid {
  align-items: start;
}
`;

export default GlobalStyle;
